import React from 'react';

const Section4 = () => {
    return (
        <section className='relative py-12 bg-body overflow-hidden'>
            <div className='relative container mx-auto px-4'>
                <div className='absolute top-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-900 to-darkBlue-900 rounded-full filter blur-4xl' />
                <div className='relative max-w-md md:max-w-8xl mx-auto'>
                    <div className='text-center mb-14'>
                        <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
                            Get started with three easy steps
                        </h2>
                    </div>
                    <div className='flex flex-wrap items-center -mx-4 mb-16'>
                        <div className='w-full md:w-1/2 px-4 mb-8 md:mb-0'>
                            <div className='relative flex max-w-md pt-12 lg:pt-20 items-end justify-end  overflow-hidden'>
                            <div className='hidden md:block'>
                                <video
                                    className='relative object-cover'
                                    src='casper-assets/video1.mp4'
                                    alt=''
                                    autoPlay={true}
                                    muted
                                    loop={true}                                        
                                />
                            </div>
                            <div className='block md:hidden'>
                                <img
                                    className='relative object-cover'
                                    src='casper-assets/gif1.gif'
                                    alt=''
                                />
                            </div>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 px-4'>
                            <div className='max-w-lg'>
                                <h3 className='font-heading text-4xl lg:text-5xl font-semibold text-white'>
                                    01. Assign ticket&nbsp;
                                </h3>
                                <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                                <p className='text-xl text-white tracking-tight'>
                                    Assign your JIRA, Linear, Github&nbsp; ticket to Octo and
                                    watch Octo push code changes to your Github repo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap items-center -mx-4 mb-16'>
                        <div className='w-full md:w-1/2 px-4 order-last md:order-first'>
                            <div className='max-w-lg'>
                                <h3 className='font-heading text-4xl lg:text-5xl font-semibold text-white'>
                                    02. Review the Solution
                                </h3>
                                <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                                <p className='text-xl text-white tracking-tight'>
                                    Review the changes and provide feedback to Octo as you
                                    would with&nbsp; a developer
                                </p>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 px-4 mb-8 md:mb-0'>
                            <div className='relative flex max-w-md pt-12 lg:pt-20 md:ml-auto items-end justify-end overflow-hidden'>
                                <div className='hidden md:block'>
                                    <video
                                        className='relative object-cover'
                                        src='casper-assets/video2.mp4'
                                        alt=''
                                        autoPlay={true}
                                        muted
                                        loop={true}                                        
                                    />
                                </div>
                                <div className='block md:hidden'>
                                    <img
                                        className='relative object-cover'
                                        src='casper-assets/gif2.gif'
                                        alt=''
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap items-center -mx-4'>
                        <div className='w-full md:w-1/2 px-4 mb-8 md:mb-0'>
                            <div className='relative flex max-w-md pt-12 lg:pt-20 items-end justify-end overflow-hidden'>
                                <div className='hidden md:block'>
                                    <video
                                        className='relative object-cover'
                                        src='casper-assets/video3.mp4'
                                        alt=''
                                        autoPlay={true}
                                        muted
                                        loop={true}                                        
                                    />
                                </div>
                                <div className='block md:hidden'>
                                    <img
                                        className='relative object-cover'
                                        src='casper-assets/gif3.gif'
                                        alt=''
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 px-4'>
                            <div className='max-w-lg'>
                                <h3 className='font-heading text-4xl lg:text-5xl font-semibold text-white'>
                                    03. Merge with confidence
                                </h3>
                                <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                                <p className='text-xl text-white tracking-tight'>
                                    Verify the proposed solution and Merge the solution to
                                    your codebase.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};

export default Section4;
