import React from 'react';

const Section7 = () => {
    return (

        <section>
            <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
                <div className='navbar-backdrop fixed inset-0 bg-violet-900 opacity-20' />
                <nav className='relative flex flex-col py-7 px-10 w-full h-full bg-white overflow-y-auto'>
                    <div className='flex mb-auto items-center'>
                        <a className='inline-block mr-auto' href='#'>
                            <img
                                className='h-10'
                                src='casper-assets/logos/octo-logo-white.png'
                                alt=''
                            />
                        </a>
                        <a className='navbar-close' href='#'>
                            <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M6 18L18 6M6 6L18 18'
                                    stroke='#111827'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        </a>
                    </div>
                    <div className='py-12 mb-auto'>
                        
                        <ul className='flex-col'>
                            <li className='mb-6'>
                                <a
                                    className='inline-block text-base text-black font-medium uppercase'
                                    href='#'
                                >
                                    FEATURED
                                </a>
                            </li>
                            <li className='mb-6'>
                                <a
                                    className='inline-block text-base text-black font-medium uppercase'
                                    href='#'
                                >
                                    SOLUTIONS
                                </a>
                            </li>
                            <li className='mb-6'>
                                <a
                                    className='inline-block text-base text-black font-medium uppercase'
                                    href='#'
                                >
                                    PRODUCTS
                                </a>
                            </li>
                            <li>
                                <a
                                    className='inline-block text-base text-black font-medium uppercase'
                                    href='#'
                                >
                                    ARTICLES
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a
                            className='flex py-2 px-4 mb-4 items-center justify-center text-sm font-medium uppercase text-violet-900 hover:text-violet-500'
                            href='#'
                        >
                            SIGN IN
                        </a>
                        <a
                            className='flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                            href='#'
                        >
                            SIGN UP
                        </a>
                    </div>
                </nav>
            </div>
        </section>);
};

export default Section7;
